@import "./src/styles/glowtouch/_variables.scss";
.hero-section {
  z-index: 0;
  background-size: cover;
  background-position: center;

  :after {
    background-color: $primary;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: '';
    z-index: -1;
  }
  h1 {
    font-size: 62px;
    color: #fff;
    font-weight: 900;

    span {
      font-weight: 100;

      i {
        font-style: normal;
        color: $yellow;
      }
    }
  }

  .content {
    padding: 8% 0px;
    color: #fff;
  }

  p {
    font-size: 24px;
    color: #fff;
  }

  .chevron-placement {
    left: 0;
    right: 0;
    margin-top: 27px;
  }
}

.checklist-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-left: .75rem;
        &::before {
            content: "";
            position: absolute;
            width: 3px;
            height: 100%;
            top: 0;
            left: 0;
            background-color: $primary;
            border-radius: 8px;
        }

}
.checklist-item-primary::before {
    background-color: $primary;
}
.checklist-item-secondary::before {
    background-color: $secondary;
}
.checklist-item-success::before {
    background-color: $success;
}
.checklist-item-info::before {
    background-color: $info;
}
.checklist-item-warning::before {
    background-color: $warning;
}
.checklist-item-danger::before {
    background-color: $danger;
}
.checklist-item-light::before {
    background-color: $light;
}
.checklist-item-dark::before {
    background-color: $dark;
}
.bg-purple{
    background-color: $purple
}
.bg-indigo{
    background-color: $indigo
}
.bg-pink{
    background-color: $pink
}
.bg-yellow{
    background-color: $yellow
}
.bg-light{
    background-color: $light
}
.bg-lighter{
    background-color: $lighter
}
@import "./src/styles/glowtouch/_variables.scss";
.ie11-widget {
  background-color: #ee6065;
  color: $white;
  display: none;

  p {
    font-size: 22px;

    small {
      font-size: 14px;
    }
  }

  .ie-widget-close {
    border: 0;
    background-color: transparent;
    font-size: 0;
    vertical-align: middle;
    outline: 0;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  .ie11-widget {
    display: block;
  } /* IE11 */
}

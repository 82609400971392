@import "./src/styles/glowtouch/_variables.scss";
@import "background-varient";
@import "list-group";
@import "badge";


@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 18px;
  position: relative;
}

.form-control.border.border-info {
  border: 2px solid #17a2b8 !important;
}

.profile-checklist {
  padding: 0;
  margin: 0;
  list-style: none;
}

@import "./src/styles/glowtouch/_variables.scss";
@import 'font';

//TODO: Use only the required scss partial from bootstrap
@import '../../node_modules/bootstrap/scss/bootstrap.scss';

@import 'button';
@import 'helpers';
@import 'nav';
// @import 'notification'; //Not used currently on dashboard
@import 'dropdown';
@import 'loader';

body {
  font-family: $font-family-base;
  background-color: $body-bg;
  color: $gray-900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::selection {
  background-color: $yellow;
  color: #fff;
}
::-moz-selection {
  background-color: $yellow;
  color: #fff;
}
::-webkit-selection {
  background-color: $yellow;
  color: #fff;
}
.container-fluid {
  max-width: 1400px;
}
a, button {
  outline: none !important;
}
.cursor-pointer{
  cursor: pointer;
}
.content-box {
  background: #ffffff;
  border: 1px solid rgba(46, 91, 255, 0.08);
  border-radius: 1px;
  box-shadow: 0 2px 11px 0 rgba(215, 215, 215, 0.5);
}
.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
}
footer{
  color: $white;
  background-color: $primary;
  a{    
    color: $white;
  }
}
@media (max-width: 480px) {
  footer .list-unstyled, footer .list-unstyled li {
    display: block !important;
    text-align: center !important;
    padding-bottom: 10px;
  }
}

.content-wrapper {
  padding: 90px 0 15px 0;
  background-color: $primary;
}
.dashboard .content-wrapper {
  background-color: transparent;
}
@media (min-width: 768px) {
  .content-wrapper {
    min-height: calc(100vh - 70px);
    display: flex;
    padding: 90px 15px 30px 15px;
  }
}
.header-pretitle {
  text-transform: uppercase;
  letter-spacing: .08em;
  color: $gray-600;
  margin-bottom: 0.2rem;
}
@media (min-width: 992px) {
  //Create account center aligned nav
  .nav-center {
    position: absolute;
    left: 0;
    right: 0;
    max-width: 270px;
  }

  .content-box {
    min-width: 580px;
  }

  .wrapper {
    &.dashboard {
      display: grid;
      grid-template-columns: $sidebar-width 1fr;

      .container-fluid {
        max-width: initial;
      }
      footer {
          background: $white;          
          color: $gray-600;
          a{                  
            color: $gray-600;
          }
      }
      
    }
  }
}
.icon-shape {
  padding: 12px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.icon {
  width: 3rem;
  height: 3rem;
}
.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $card-box-shadow;
  border: 0;
}    


.table{  
  thead th {
    background-color: $lighter;
    text-transform: uppercase;
    font-size: .8125rem;
    font-weight: 600;
    letter-spacing: .08em;
    color: $gray-600;
    border-bottom-width: 1px;

    &[title~="SortBy"]::after {
      content: url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='%2395AAC9'/></svg>");
      margin-left: .25rem;
    }
  }
  tbody td, tbody th, thead th {
    vertical-align: middle
  }
}
.table-sm {
  font-size: .8125rem;

   thead th {
      font-size: .625rem;
    }
}
.table-xs {
  font-size: .8125rem;

   thead th {
      font-size: .625rem;
      padding: .3rem;
    }
    tbody td {
       padding: .3rem;
     }
}

.card-table {
  margin-bottom: 0;
}

.alert {
  color: #fff !important;
}
.TableScrollbar {
    height: auto !important;
    max-height: 300px;
}
.css-26l3qy-menu{
    border-radius: 0.4375rem;
    box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1) !important;
    padding: 1rem 0 !important;
}
.Toastify__toast{
  font-family: $font-family-base !important;
}
.Toastify__toast--success {
    background: $success !important;
}
.date {
  background: url(/assets/img/calendar.svg) no-repeat right 0.5rem center;
  background-size: 15px;
}
.list-group-item.active svg {
  color: #fff !important;
}
.modal {
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position:fixed;
  background: white;
  width: 80%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%)
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
@import "./src/styles/glowtouch/_variables.scss";
.box-block {
  max-width: 480px !important;

  h3 {
    font-size: 24px;
    display: block;
    text-align: center;
    font-weight: 600;
    line-height: 2.5;
  }

  &-content {
    padding: 60px 40px;

    ul.plans-list {
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e6e6e6;
        padding: 25px 30px;

        h5 {
          font-size: 24px;
          color: #232a30;
          margin: 0;
        }

        span {
          font-size: 24px;
          color: #5b5f64;
        }

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
  }
}

.active-plan {
  width: 30px !important;
  height: 36px !important;
}

.box-block-800 {
  max-width: 800px !important;
}

@import "./src/styles/glowtouch/_variables.scss";
aside.sidebar {
  position: relative;
  width: $sidebar-width;
  background-color: $sidebar-bg;
  border-right: solid 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  background-repeat: no-repeat;
  background-position: left;

  nav {
    padding: 0;

    .navbar-brand {
      margin: 0;
      height: 70px;
    }

    .navbar-nav {
      a.nav-link {
        color: $sidebar-menu-text;
        font-size: .9375rem;
        border-bottom: dotted 1px rgba(255,255,255,0.1);
        padding: 13px 20px;

        &:hover,
        &:active,
        &:focus {
          color: $white;
          background-color: $sidebar-menu-hover-bg;
        }

        &.active {
          color: $white;
          background-color: $sidebar-menu-hover-bg;
          box-shadow: inset -7px 0 0 $warning;
        }

        svg {
          margin-right: 10px;
        }
        .material-icons {
            font-size: 18px;
            vertical-align: middle;
            margin-right: 10px;
        }
      }
    }
  }
}

// Hide for md and bigger screens
.sidebar-menu-trigger {
  display: block;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  margin: 15px 15px 0;
}

@media (max-width: 991px) {
  aside.sidebar {
    background-size: $sidebar-width;
    position: fixed;
    left: -$sidebar-width;
    top: 44px;
    bottom: 0;
    z-index: 99;
    -moz-box-shadow: 1px 0 1px rgba(0, 0, 0, 0.07);
    -webkit-box-shadow: 1px 0 1px rgba(0, 0, 0, 0.07);
    box-shadow: 1px 0 1px rgba(0, 0, 0, 0.07);
    -moz-transition: -moz-transform 0.3s ease-in-out 0s;
    -o-transition: -o-transform 0.3s ease-in-out 0s;
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    -webkit-transition-delay: 0s;
    transition: transform 0.3s ease-in-out 0s;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    &.sidebar-visible {
      -ms-transform: translate($sidebar-width, 0);
      -webkit-transform: translate($sidebar-width, 0);
      transform: translate($sidebar-width, 0);
    }

    &.sidebar-hidden {
      -ms-transform: translate(0, 0);
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }

    .close-sidebar {
      background-color: transparent;
      border: 0;
      font-size: 0;

      svg {
        g {
          g {
            fill: $sidebar-close-btn;
          }
        }
      }
    }
  }

  .sidebar-overlay {
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    font-size: 0;
  }
}

@media (min-width: 992px) {
  aside.sidebar {
    section {
      background-attachment: fixed;
      background-size: $sidebar-width;
      width: 100%;
      max-width: $sidebar-width;
      position: fixed;
      height: 100vh;
      border-right: $sidebar-border;
    }
  }

  .sidebar-overlay {
    display: none;
  }

  // Hide for md and bigger screens
  .sidebar-menu-trigger {
    display: none;
  }
}

@import "./src/styles/glowtouch/_variables.scss";
.coaching-cards {
  display: grid;
  grid-gap: 15px;

  .item {
    background: #ffffff;
    border: 1px solid rgba(46, 91, 255, 0.08);
    border-radius: 1px;
    box-shadow: $metrics-card-shadow;

    .card-body {
      padding: 1rem;
    }

    .card-title {
      font-size: 16px;
      font-weight: 300;
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;
      min-height: 20px;
      margin-bottom: 0;
      align-items: start;

      i {
        width: 32px;
        height: 30px;
        display: inline-block;
        margin-right: 7px;
        margin-top: -1px;
        background-repeat: no-repeat;
        background-position: left top;
        opacity: 0.5;
      }

      svg.lock {
        width: 15px;
      }
    }

    &.inactive {
      position: relative;

      .card-title {
        color: lighten(black, 80%);

        i {
          opacity: 0.2;
        }
      }

      .scan-results {
        &-col {
          .count {
            color: lighten(black, 80%);
          }
          small {
            color: lighten(black, 80%) !important;
          }
        }
      }
    }
    .upgrade-txt {
      position: relative;
      left: 0;
      right: 0;
      bottom: -10px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 400;
      color: lighten(black, 50%);
      align-items: center;
      align-content: center;
    }

    .cat-details {
      font-size: 12px;
      text-align: center;
      padding: 7px 0;
      display: block;
      color: #5f5e5e;
      border-radius: 3px;
      box-shadow: 0 0 1px 1px rgba(240, 240, 240, 0.5);
      border: solid 1px #e6e6e6;
      background-color: #ffffff;
      text-transform: none;

      &:hover {
        background-color: #ffffff;
      }
    }
  }
}

@media (min-width: 576px) {
  .coaching-cards {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 1200px) {
  .coaching-cards {
    grid-template-columns: repeat(1, 1fr);
  }
}

.badge-soft-primary {
    color: $primary;
    background-color: rgba($primary, .1);
}
.badge-soft-success {
    color: $success;
    background-color: rgba($success, .1);
}
.badge-soft-info {
    color: $info;
    background-color: rgba($info, .1);
}
.badge-soft-warning {
    color: $warning;
    background-color: rgba($warning, .1);
}
.badge-soft-danger {
    color: $danger;
    background-color: rgba($danger, .1);
}
.highlight {
  color: $primary;
}

.font-weight-medium {
  font-weight: $font-weight-medium;
}

.bg-light-blue {
  background-color: #ecf9f9;
}

code {
  color: $black;
}

//Used for profile completeness percent display
.CircularProgressbar-path {
  stroke: $primary !important;
}

//Plans page discount badge
.save-badge {
  .discount {
    color: $save-badge;
    svg {
      path {
        fill: $save-badge;
      }
    }
  }
}

//Not Found
.not-found {
  h1 {
    font-size: 125px;
    line-height: 1;
    font-weight: 300;
  }
}

// Category tooltip for plan details on Overview
.show-categories {
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}
.tippy-tooltip {
  .tippy-content {
    .category-tooltip {
      h6 {
        font-size: 16px;
      }
      ul {
        li {
          font-size: 14px;
          color: #fff;
          padding: 3px 0;
        }
      }
    }
  }
}

//Landing footer links
ul.foot-nav {
  li {
    a {
      margin-top: 7px;
      font-size: 14px;
      color: white;
    }
  }
}

//Dashboard footer links
ul.dashboard-foot-nav {
  justify-content: center;
  flex-direction: column;

  li {
    font-size: 14px;
    padding: 4px 0;

    a {
      font-size: 14px;
      color: $body-color;
    }
  }
}

.box-block {
  h3 {
    background: $block-head-bg;
    color: $white;
  }
}

@media (min-width: 768px) {
  ul.dashboard-foot-nav {
    flex-direction: row;

    li {
      border-right: 1px solid $gray-400;
      padding: 0 10px;

      &:last-child {
        border-right: 0px solid $gray-400;
      }
    }
  }
}

// Campaign Disclaimer
.campaign-disclaimer {
  p {
    line-height: 1;

    small {
      font-size: 11px;
    }
  }
}

.modal-header-text,
.modal-uses-div {
  color: $primary;
}
.modal-threats-header,
.modal-uses-text {
  color: $save-badge;
}

// Card
.card {
  border-radius: $border-radius;
}
.shadow {
  box-shadow: $box-shadow;
}

// Popover Categories
#popover-categories {
  display: none;
}

.tippy-tooltip {
  .tippy-content {
    .field-popover {
      padding: 10px;

      h6 {
        font-size: 16px;
        font-weight: bold;
        color: #fff;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          margin: 0 0 5px;
          padding: 3px 0 4px;
          display: block;
          font-size: 14px;
          color: #fff;
          border-bottom: 1px solid #606060;

          &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

// Account settings menu
.account-settings-menu {
  a {
    padding: 10px 0;
    margin: 0 10px 20px;
    color: #232a30;
    text-decoration: none;

    &.active {
      font-weight: 700;
      border-bottom: #dc0019 3px solid;
    }
  }
}

@media (min-width: 768px) {
  .account-settings-menu {
    a {
      margin: 0 25px 20px;
    }
  }
}

.text-link-grey {
  a {
    color: #797e85;
    text-decoration: none;

    span {
      text-decoration: underline;
    }

    &:hover {
      color: #797e85;
      span {
        text-decoration: none;
      }
    }
  }
}

.hr-heading {
  position: relative;

  &.hr-billing-details {
    &::after {
      content: 'Billing Account Details';
    }
  }
  &.hr-card-details {
    &::after {
      content: 'Card Details';
    }
  }
  &::after {
    display: block;
    position: absolute;
    top: -14px;
    right: 25px;
    background: $white;
    padding: 0 5px;
    font-size: 16px;
  }
}

@import "./src/styles/glowtouch/_variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap');

body {
color: #371d66;
    /* font-size: .9375rem; */
    font-size: 14px;
}
/* aside.sidebar {
  background: #0a48b3;
} */
/* @media (min-width: 992px){
.dashboard header {
    left: 260px;
    background: #FFF !important;
    border-bottom: #e6e9f1 solid 1px;
    box-shadow: #ededed 2px 6px 12px 0px; 
}
} */
aside.sidebar nav .navbar-nav a.nav-link.active {
  color: #ffffff;
  background-color: #49127a;
  box-shadow: inherit;
  border-left: 6px solid #ff7300;
}
aside.sidebar nav .navbar-nav a.nav-link {
  color: #ffffff;
}
/* .navbar-brand img{
  width:54px;
  margin-right: 8px;
} */
aside.sidebar nav .navbar-brand {
  font-size: 24px;
  font-weight: 500;
  border-bottom: #003da7 solid 1px;
}
.dashboard header .navbar .navbar-nav .nav-link {
  color: #1d1717 !important;
}
.navbar-light .navbar-brand {
  color: #ffffff;
}
.dropdown-menu.show {
  /*word-break: break-all;*/
  padding: 10px;
}
.user-icon {
  width: 38px;
  height: 38px;
  background: #ffffff;
  font-size: 20px;
  border-radius: 12%;
  padding: 4px 0px 0 0;
  color: #3a1c68;
  text-align: center;
  margin: 3px 0px 0px 0px;
}
header .myaccount-dropdown .dropdown-menu a {
    padding: 0 0px !important;
}

.card-header {
  border-bottom: 1px solid #e6e9f1 !important;
  background: #371d66;
  color: #FFF;
}
.card {
  margin-bottom: 17px;
  border: 1px solid #e6e9f1 !important; 
}
.card-body {
  padding: 4px 15px 4px 15px;
}
a.card-link{
  color: #12263f !important;
}
/* SKK*/
.card-form {
  margin: -30px 0px 20px 0px;
  border: none !important;
  box-shadow:none !important
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
  font-weight: 400;
}
.MuiTableCell-body {
  color: #283E59 !important;
}
td, .MuiTableCell-root {
  font-size: 13px !important;
  font-family: "Rubik", sans-serif !important;
  font-weight: 400 !important;
  border-bottom: 1px solid #e6e9f1 !important;
}
.form-control-sm {
  /*color: #12263f;*/
  background: #FFF;
}
@media (min-width: 768px){
.content-wrapper {
    padding: 88px 5px 30px 5px;
}
}
.text-muted {
  color: #95aac9!important;
  font-weight: 400;
}
@media (min-width: 992px){
.wrapper.dashboard footer {
    background: #FFFFFF;
    color: #7b90af;
    font-weight: 400;
}
}
.table thead th {
  font-size: 11px !important;
  font-weight: 500;
  color: #FFF;
  background: #371d66;
}
h2, .h2 {
  font-size: 20px;
}
h2.count {
  color: #0a48b3;
}
h3.page-lable {
  font-size: 20px;
  margin-left: 5px;
}
.table-sm th, .table-sm td {
  padding: 10px 25px;
}

.MuiTableRow-root:hover{
  background: #f3f8ff;
  /*cursor: pointer;*/
}
tr:hover{
  background: #f3f8ff;
  /*cursor: pointer;*/
}

/*.selected_multi_input.cursor-pointer.card-header {
  background: #f8f9fc;
  padding: 8px 11px;
}*/

/*SKK*/
.selected_multi_input.cursor-pointer.card-header {
  background: #f8f9fc;
  /*padding: 8px 11px;*/
  padding: 8px 10px 9px 10px !important; 
  color: #000;
}
.error-text{
  font-size:14px;
}
.tableCol1{
  width:150px;
}
.tableCol2{
  width:350px;
  border:solid 1px #ff0000;
}
.cellSortIcon::after{
  display:none;
}

.selectdiv {
  position: relative;
  width:100%;
  /*Don't really need this just for demo styling*/
}

.selectdiv:after {
    font-family: "FontAwesome";
    content:close-quote;
    color: #252631;
    right: 10px;
    top: 1px;
    height: 37px;
    padding: 15px 0px 0px 8px;
    position: absolute;
    pointer-events: none;
    background: #FFF url(/assets/img/dwn-arrow.png) no-repeat center;
} 

/* IE11 hide native button (thanks Matt!) */

select::-ms-expand {
  display: none;
} 

.selectdiv select {
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    height: 40px;
    float: right;
    margin: 0px 0px;
    padding: 0px 13px;
    font-size: 14px;
    line-height: 1.75;
    color: #12263F;
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #d2ddec;
    -ms-word-break: normal;
    word-break: normal;
    border-radius: 5px;
}
.selected_multi_input .MuiSvgIcon-root{
  color: #ccc;
  width:20px;
}
.css-yk16xz-control{
  border:1px solid #D2DDEC !important;
  color: #12263F !important;
}
.css-1wa3eu0-placeholder, .custom-select
{
  color: #12263F !important;
  font-size:14px;
}
.tblCustomDropdown select{
  height: 30px !important;
  font-size:13px !important;
}
.tblCustomDropdown::after{
  height: 25px !important;  
}
.d-grid{
  display:grid;
}
.file-input{
  width:300px !important;
}
.css-1okebmr-indicatorSeparator{
  display: none;
}
h3.name {
  font-size: 25px;
}
h3.text-heading {
  font-size: 20px;
}
.dash_card_header{  
  color: #778ca2;
  /*color:#252631;*/
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 400;
}
.dash_card_header_red, span.dash_card_header_red{
  background: #f44336;
  /*background: #f443364d;*/
}
.dash_card_header_green, span.dash_card_header_green{
  background: #009688;
}
.dash_card_header_blue, span.dash_card_header_blue{
  background: #17acee;
  /*background: #f443364d;*/
}
.dash_card_border_bottom_red{
  border-bottom: solid 1px #f44336 !important;
  /*background: #f443364d;*/
}
.dash_card_border_bottom_green{
  border-bottom:solid 1px #009688 !important;
}
.card_border{
  border-bottom: solid 3px #f443364d !important;
}
.text-sm{
  font-size:12px;
}
.add_icon{
  margin: -2px 3px 0px 0px;
}
.dropdown_label{
  /*color: #0a48b3;*/
  padding: 0px 0px 0px 2px;
  margin: -4px 0px 3px 0px;
  font-size:14px;
}
.card-left-border{
  border-left:#3b1c68 solid 5px !important;
}
.program-bar-card{
  border-left: #17acee solid 5px !important;
  border-right: #17acee solid 5px !important;
  padding: 5px 0px;
}
.week-title
{  
  background: #0a48b3;
  display: initial;
  padding: 11px 6px;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  float:left;
  width:40px;
}  
.coaching_week_section{
  margin:16px 0px -16px 0px;
}
.coaching_value{
  font-size: 16px;

}
.coaching_agent_name{
  font-size: 18px;

}
a:hover {
  text-decoration:none;
}
/* h3.metric_value{
  color:#371d66;
} */
.custom-input-label::after{
  background-color:#08398f !important;
}
.css-42igfv{  
  position: absolute !important;
  top:50% !important;
  left:50% !important; 
}
/*.bZGccd {
  z-index: 99999 !important;
  height:100vh;
  width:100vw;
  position:fixed;
  top:0;
  left:0;
  display:flex;
  align-content:center;
  justify-content:center;
}*/

.react-datepicker-wrapper {
    display: block !important;
}
.coaching_title{
  margin-top: -15px;
}
.streched_row{
  margin:0px 0px 0px 0px;
}
.card-left-border-red{
  border-left:solid 5px #E63757 !important;
}
.card-left-border-green{
  border-left:solid 5px #00D97E !important;
}
.card-agent-name{
  max-width: 170px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  float: left;
  font-size: 18px;
}

.tbldata td{
  padding: 10px 0px 10px 0px !important;
}
.tbl2 td{
  padding: 10px 10px 10px 0px !important;
}
.badge{  
  margin-left: -1px !important;
  font-size: 56%;
}
.settings-tab{
  flex-direction: initial;
}
a.d-flex.align-items-center.list-group-item.list-group-item-action.active {
  background: #FFF;
  border: none;
  border-bottom: #0a48b3 solid 2px;
  color: #0a48b3;
}
.list-group-item.active svg {
  color: #0a48b3 !important;
}
a.d-flex.align-items-center.list-group-item.list-group-item-action{
  justify-content: center;
}
.test1{
  position:relative;
  top:40px;
}
.test2{
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.settings-nav {
  background: #4b1872;
}
.settings-nav a{
  font-size: 18px !important;
  display: flex;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #ff7300 !important;
}
.MuiSwitch-colorSecondary.Mui-checked {
  color: #ff7300 !important;
}
/*.form-section{
  border-left: #ff9800 2px solid;
  margin: 2px -12px;
  padding-left: 11px;
  margin-bottom: 25px;
}
.form-section:nth-child(1n){
  border-left: #ff9800 2px solid;
}
.form-section:nth-child(2n){
  border-left: #673ab7 2px solid;
}

.form-section:nth-child(3n){
  border-left: #2196f3 2px solid;
}*/

.form-section{  
  margin: 2px -12px;
  padding-left: 11px;
  margin-bottom: 25px;
}
.form-section-orange{
  border-left: #ff9800 2px solid;
}
.form-section-purple{
  border-left: #673ab7 2px solid;
}

.form-section-blue{
  border-left: #2196f3 2px solid;
}

/*.form-section-title{  
  padding:3px;
  margin-left:-12px;
  color:#fff;
  background: #673ab7;
}*/

.form-section-title{    
  /*padding-bottom:3px;*/
  margin-bottom:3px;  
  /*margin-left:-13px;*/
}
.form-section-subtitle{  
  margin-bottom:20px;
  font-size:14px;
  /*margin-left:-13px;*/
}
.form-section-title-orange{
  border-bottom:solid 1px #ff9800;
}
.form-section-title-purple{
  /*border-bottom:solid 1px #673ab7; */ 
}
.form-section-title-blue{
  border-bottom:solid 1px #2196f3;   
}

.qualityPlan{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 182px;
}
.qualityPlan-date{
  width:114px;
}
span.qualityPlan-status {
  color: #0a48b3;
  border: 1px solid #0a48b3;
  float: right;
  border-radius: 3px;
  padding: 1px 8px;;
}
span.qualityPlanheading{
  font-size: 16px;
  margin-bottom: 5px;
}
.MuiFormControl-root.MuiTextField-root.w-100.mt-0.pt-0.MuiFormControl-marginNormal input {
  padding: 8px 8px 11px 8px;
  background: #FFF;
  border: #d2ddec solid 1px;
  border-radius: 5px;
}
.MuiInput-underline:before, .MuiInput-underline:after{
  display: none;
}
.form-group-mb {
  margin-bottom:0px;
}
.resolved_switch {
  margin-left : -8px;
}
.MuiInputBase-input.MuiInput-input {
  font-size: 15px;
  color: #12263F;
  /*border: 1px solid #D2DDEC;  */
}
.MuiPickersToolbar-toolbar, .MuiPickersClock-pin, .MuiPickersClockPointer-pointer, .MuiTypography-root.MuiPickersClockNumber-clockNumber.MuiPickersClockNumber-clockNumberSelected.MuiTypography-body1 {
  background-color: #08398f !important;
}
.timepicker_border {
  border: 1px solid #D2DDEC !important;
}
.coaching_sub_header {
  font-weight:500;
}
.week_completed_checkbox{
  padding-left:5px;
}

.password_block {
    position: relative;
}

.password_hide {
  position: absolute;
  width: 32px;
  height: 26px;
  right: 8px;
  top: 36px;
  outline: none;
  cursor: pointer;
  font-size: 0;
  background-image: url('../img/dashboard/close-eye.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 23px;
  opacity: 0.2;
}

.password_show {
  position: absolute;
  width: 32px;
  height: 26px;
  right: 8px;
  top: 36px;
  outline: none;
  cursor: pointer;
  font-size: 0;
  /*background-image: url('../img/dashboard/close-eye.png');*/
  background-repeat: no-repeat;
  background-position: center;
  background-size: 23px;
  opacity: 0.2;
  background-image: url('../img/dashboard/open-eye.png');
}

.tbl_row_spinner {
  border:none !important;
  padding:0px !important;  
  margin: 0px !important; 
}

.fixed-top {
  z-index:999 !important;
}

.overlay_spinner_center {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;  
  z-index:999 !important;
}

.bordertest {
  border:solid 1px #ff0000;
}

.weekrange_input{
  font-size: 14px;
  border: solid 1px #D2DDEC !important;
  color: #333333 !important;
  background: #eaebeb;
}

/*SP new theme shine*/
.content-wrapper{
  background:url(../img/shine-login-bg.png) no-repeat bottom left;
  background-size: cover;
}

.dashboard .content-wrapper-login{
  background:none !important;
  background-size: cover;
}

.text-blue{
  color:#371d66;
}
.navbar-brand img{
margin-top: 12%;
}
.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  max-width: 1650px;
}
h1.hero-home{
  font-size: 87px;
  font-weight: 900;
  line-height: 79px;
  margin-top: 145px;
  font-family: 'Open Sans', sans-serif;
}
.text-orange{
  color:#ff7300;
}
.hero-section p {
  font-size: 39px;
}
@media (min-width: 768px){
.content-wrapper {
    min-height: calc(100vh - 123px);
}
}
@media (min-width: 768px){
  .dashboard .content-wrapper {
      min-height: calc(100vh - 71px);
      background: none;
  }
  }

aside.sidebar {
background: rgb(58,28,104);
background: linear-gradient(180deg, rgba(58,28,104,1) 0%, rgba(114,14,137,1) 100%);
}
@media (min-width: 992px){
  .dashboard header {
      left: 260px;
      background: rgb(58,28,104);
      background: linear-gradient(90deg, rgba(58,28,104,1) 0%, rgba(114,14,137,1) 100%);
      box-shadow: #ededed 8px 11px 13px 0px;
  }
  }
  aside.sidebar nav .navbar-brand{
    background:#3a1c68 !important;
  }
  .dashboard .navbar-brand img{
    margin-top:0px;
  }
  .navbar-expand-lg .navbar-nav h3.page-lable {
    color: #FFF;
}
.login-container {
  min-height: calc(87vh - 307px);
  margin-top: 50px;
}
aside.sidebar nav .navbar-nav a.nav-link {
  font-size: 18px;
  border-top: #614986 solid 1px;
  padding: 28px 20px;
}
.dashboard .fixed-top .container-fluid{
  background:url(../img/glowtouch-strip.png) no-repeat top left;
}

header {
  min-height: 95px;
}
@media (min-width: 768px){
.content-wrapper {
    padding: 123px 5px 30px 10px;
}
}
.card{
  background:#eaebeb ;
}
.dashboard .content-wrapper{
  background: #FFF;
}
.card-body {
  padding: 15px !important;
}
.badge-soft-success {
  color: #371d66;
  background-color: #ffce00;
  font-size: 13px;
}
.badge-soft-danger {
  color: #FFF;
  background-color: #e90101;
  font-size: 13px !important;
}
.text-secondary {
  color: #3f1b6b !important;
}
.card {
  font-size: 16px;
  color: #371d66;
  box-shadow: none;
}
.card:hover{
  background:#371d66;
  color: #FFF;
}
.card:hover .text-secondary{
  color: #FFF !important;
}
.card .right-split-border{
  border-right: 1px solid #371d66;
}
.card:hover .right-split-border{
  border-right: 1px solid #ffce00;
}
.css-yk16xz-control{
  background-color:#eaebeb !important;
}
.no-bgr:hover{
background: none !important;
color: #371d66 !important;
}
.btn-primary{
  background-color: #710e88;
  border-color: #671183;
}
.highlight{
  color: #3b1c68;
}
.bg-primary {
  background-color: #371d66 !important;
  color: #FFF;
}
.no-bg{
  background: #FFF;
  color: #3a1c68; 
}
.no-bg:hover{
  background: #FFF;
  color: #3a1c68;
}
th {
  background: #371d66;
  color: #FFF;
}
.text-primary {
  color: #371d66 !important;
}
@media (max-width: 991px){
.dashboard header {
    left: 0;
    background: #3a1c68;
    background: linear-gradient(90deg, #3a1c68 0%, #720e89 100%);
    box-shadow:none;
}
aside.sidebar{
  top:29px;
}
.card .right-split-border{
  border: 0px;
}
.card:hover .right-split-border{
  border: 0px;
}
header{
  min-height: 61px;
}
.dropdown_label{
  margin: 4px 0px 3px 0px;
}
}
.dashboard-footer{
  position: fixed;
  bottom: 0px;
  color: #FFF;
  width: 260px;
  border-top: #8b3f9f solid 1px;
  padding: 18px 18px 18px 18px;
  background: #6e0f87;
}
.hero-section :after {
  background-color: #ffffff;
}
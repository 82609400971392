// Button Styles
.btn {
  &.btn-tertiary {
    background-color: $tertiary;
    border-color: $tertiary;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: $btn-border-radius;
    color: $sidebar-btn-txt-color;

    &:active,
    &:focus,
    &:hover {
      background-color: lighten($tertiary, 5%);
      border-color: lighten($tertiary, 5%);
      box-shadow: 0 0 0 0.2rem rgba(0, 170, 167, 0.5);
      color: $sidebar-btn-txt-color;
    }
  }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: $primary;
  border-color: $primary;
}

.btn-primary.upgrade:not(:disabled):not(.disabled):active:focus,
.btn-primary.upgrade:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.upgrade.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: $primary;
  border-color: $primary;
}

.btn-tertiary:not(:disabled):not(.disabled):active,
.btn-tertiary:not(:disabled):not(.disabled).active {
  background-color: $tertiary;
  border-color: $tertiary;
  color: $sidebar-btn-txt-color;
}

.link-button {
  margin: 0 4px;
  color: $gray-900;
  font-weight: 400;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

@import "./src/styles/glowtouch/_variables.scss";
.login-container{
  min-height:calc(100vh - 210px);
}
.login {
  .form-group {
    margin-bottom: 1.3rem;

    &.passwordBlock {
      position: relative;

      .show-hide {
        position: absolute;
        width: 32px;
        height: 26px;
        right: 8px;
        top: 36px;
        outline: none;
        cursor: pointer;
        font-size: 0;
        background-image: url('../img/dashboard/close-eye.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 23px;
        opacity: 0.2;

        &.active {
          background-image: url('../img/dashboard/open-eye.png');
        }
      }
    }
  }
  .form-label {
    font-size: 16px;
    margin-bottom: 5px;
    text-transform: initial;
  }
  .form-control {
    background: #fff;
    border: 1px solid #cccdce;
    height: calc(1.5em + 0.75rem + 7px);
    // border-radius: 2px;
  }

  .error-msg {
    margin-top: 5px;
    color: red;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #cccdce;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  .gray-textlink {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

@media (min-width: 992px) {
  .dashboard header {
    left: 260px;
  }
}
header {
  min-height:70px;
  display: flex;
  align-content: center;
  .navbar .navbar-nav .nav-link {
    color: #fff !important;
    display: flex;
    font-size: 1.025rem;
    align-items: center !important;

    &::after{
      display:none;
    }
  }
  .main-nav {
    a {
      font-size: 16px;
      position: relative;
      color: #fff;
    }
  }
  .navbar {
    padding: 0;
    height: 100%;
    
    .navbar-toggler {
        border: none;
    }
    .navbar-toggler-icon {
        background: none;
    }
    .navbar-toggler-icon:before,
    .navbar-toggler-icon:after {
      background-color: #fff;
      content: '';
      display: block;
      height: 2px;
      transition: all 200ms ease-in-out;
    }
    .navbar-toggler-icon:before {
      box-shadow: 0 9px 0 #fff;
      margin-bottom: 16px;
    }


  }
}

@media (min-width: 992px) {
  header {
    .main-nav {
      margin-left: 50px;

      a {
        padding: 0;
        margin-right: 40px;

        &.active,
        &:hover {
          &::after {
            position: absolute;
            height: 3px;
            background: $primary;
            content: '';
            left: 0px;
            right: 0px;
            bottom: -27px;
          }
        }
      }
    }
  }
}

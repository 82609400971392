header {
  .myaccount-dropdown {
    .dropdown-menu {
      background: $white;
      border-radius: 0.4375rem;
      box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
      border: 0;
      font-size: 0.875rem;

      figure {
        width: 60px;
        margin: 15px auto 10px;
      }

      p {

        span {
          font-weight: 400;
          display: inline-block;
        }

        .optout {
          background-color: $light;
          border-radius: 2px;
          padding-right: 7px;

          &-count {
            background-color: $save-badge;
            color: $white;
            border-radius: 2px;
            padding: 0 6px;
            margin-right: 6px;
          }
        }
      }

      a {
        padding: 0 15px;
        color: $gray-600;

        &:hover,
        &:active,
        &:focus {
          color: $gray-900;
          background: transparent;
        }
        .material-icons {
            font-size: 18px;
            vertical-align: middle;
            margin-right: 5px;
        }
        
      }
    }
    .dropdown-divider {
      margin: 0.5rem 0;
    }

    .dropdown-item.active,
    .dropdown-item:active {
      color: $primary;
      text-decoration: none;
      background-color: #fff;
    }
  }
}
@media (max-width: 991px) {
  .navbar-nav .dropdown-menu {
    position: absolute;
    width: 200px;
  }
  // Only for dashboard pages
  .wrapper.dashboard {
    .navbar-nav .dropdown-menu {
      left: -105px;
    }
  }
}

@media (min-width: 992px) {
  header {
    .myaccount-dropdown {
      .dropdown-menu {
        left: inherit;
        right: 0;
        min-width: 228px;
      }
      .dropdown-item.active {
        background-color: transparent;
        color: $primary;
      }
    }
  }
}

.multi-select {
      .dropdown-heading {
        display: inline-block !important;
        height: calc(1.5em + 1rem + 2px) !important;
        padding: 0.5rem 2rem 0.5rem 0.75rem;
        font-size: 0.9375rem;
        font-weight: 400;
        line-height: 1.5;
        color: #12263F !important;
        vertical-align: middle;
        background: #FFFFFF url("data:image/svg+xml,%3csvg viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1 1L4.25 4.25L7.5 1' stroke='%2395AAC9' stroke-width='1.08333' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e") no-repeat right 0.75rem center/0.75em 0.75em;
        border: 1px solid #D2DDEC !important;
        border-radius: 0.375rem !important;
    }
    .dropdown-heading-dropdown-arrow {
      display: none !important;
    }
}